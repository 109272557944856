@for $i from 1 through 100 {
    .mb-#{$i} {
        margin-bottom: #{$i}px;
    }
}

@for $i from 1 through 100 {
    .mt-#{$i} {
        margin-top: #{$i}px;
    }
}


@for $i from 1 through 100 {
    .pb-#{$i} {
        padding-bottom: #{$i}px;
    }
}

@for $i from 1 through 100 {
    .pt-#{$i} {
        padding-top: #{$i}px;
    }
}

@for $i from 1 through 100 {
    .px-#{$i} {
        padding-left: #{$i}px !important;
        padding-right: #{$i}px !important;
    }
}

@for $i from 1 through 100 {
    .py-#{$i} {
        padding-top: #{$i}px !important;
        padding-bottom: #{$i}px !important;
    }
}

@media(max-width: 480px) {
    @for $i from 1 through 100 {
        .pt-#{$i} {
            padding-top: #{0}px !important;
        }
    }

    @for $i from 1 through 100 {
        .mb-#{$i} {
            margin-bottom: #{$i/2}px;
        }
    }
}