.custom-container {
    margin: auto;
    padding: 0 15px;
    width: 100%;
}

.heading40 {
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    font-family: "Red Hat Display";

    span {
        color: $gray-300;
    }
}

.main-heading32 {
    font-family: $font-manrope;
    font-size: 24px;
    font-weight: 600;
    line-height: 35.2px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(79, 79, 79, 1);
}

.secondary-heading {
    font-family: $font-manrope;
    font-size: 32px;
    font-weight: 600;
    line-height: 35.2px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(79, 79, 79, 1);
}

.heading-primary {
    font-family: $font-redHat;
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
    text-align: left;
    color: $primary;
}

.border {
    border: 1px solid #eee;
}

/*banner*/
.css-79elbk {
    overflow: hidden;
}

.slick-dots {
    li {
        span {
            transition: all 0.3s;
        }

        &.slick-active {
            span {
                background-color: red !important;
                width: 1rem !important;
            }
        }
    }
}

// custom button
.custom-btn {
    &.MuiButtonBase-root {
        border-radius: 74px;
        padding: 12px 20px;
    }

    &.MuiButton-contained {
        background: $primary;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        font-family: "Red Hat Display";
        box-shadow: none;
    }

    &.MuiButton-outlined {
        background: transparent;
        font-family: "Manrope";
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.02em;
        border-color: rgba(0, 106, 164, 1);
    }

    &:disabled {
        .MuiCircularProgress-root {
            opacity: 0.5;
        }
    }
}

.btn {
    background: transparent;
    border: none;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.user-avatar {
    width: 32px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background: $yellow;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-redHat;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
}

.error {
    color: $red;
    line-height: 23px;
    font-size: 16px;
    font-family: $font-redHat;
    margin-top: 14px;
}

.text-center {
    text-align: center;
    font-family: $font-redHat;
}

.text-muted {
    color: gray;
}

// custom button end
@media (min-width: 768px) {
    .custom-container {
        max-width: 720px;
    }

    .heading-primary {
        font-size: 48px;
    }

    .heading40 {
        font-size: 40px;
    }

    .main-heading32 {
        font-size: 32px;
    }
}

@media (min-width: 992px) {
    .custom-container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .custom-container {
        max-width: 1140px;
    }
}

// MUI overrides
.MuiIconButton-root {
    padding: 0 !important;
}