.font-redHat {
    font-family: $font-redHat;
}

.font-dmSerif {
    font-family: $font-dmSerif;
}

.font-poppins {
    font-family: $font-poppins;
}

.font-calibri {
    font-family: $font-calibri;
}

.font-swissBT {
    font-family: $font-swissBT;
}

.font-swissLT {
    font-family: $font-swissLT;
}

.font-avenir {
    font-family: $font-avenir;
}

.font-mulish {
    font-family: $font-mulish;
}

.font-manrope {
    font-family: $font-manrope;
}

.font-inter {
    font-family: $font-inter;
}