.input-label {
    font-family: "Red Hat Display";
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    color: rgba(51, 51, 51, 1);
    margin-bottom: 8px;
}

.input-container {
    display: flex;
    flex-direction: column;

    input {
        border: 1px solid $border-input;
        width: 100%;
        padding: 12px 19px;
        border-radius: 4px;
        font-weight: 400;
        font-family: "Manrope";
        font-size: 16px;
        line-height: 23px;

        &::placeholder {
            color: $border-input;
        }
    }
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}