.footer-common-box {
    padding: 70px 0;

    h3 {
        position: relative;
        min-height: 52px;
        display: flex;
        align-items: center;
        padding-left: 21px;
        margin-bottom: 1.5rem;

        &:before {
            content: '';
            background-color: rgb(255, 174, 0);
            width: 5px;
            height: 52px;
            display: block;
            position: absolute;
            left: 0px;
            top: 0px;
        }

        span {
            font-family: "DM Serif Display";
            font-size: 1.55rem !important;
            letter-spacing: 1.56px;
        }

        strong {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        br {
            display: none;
        }
    }

    // p {
    //     line-height: 28px;
    //     span {
    //         line-height: 28px;
    //     }
    // }
    ul {
        list-style: none;
        padding: 20px 0;

        li {
            margin-top: 10px;
            position: relative;
            padding-left: 15px;

            &:before {
                content: '';
                width: 5px;
                height: 5px;
                background-color: #000;
                position: absolute;
                left: 0px;
                top: 7px;
                border-radius: 50%;
            }

            &:first-child {
                margin-top: 0px;
            }
        }
    }
}

.RegisterBtns {
    display: none;
}

.standout-box {
    ul {
        list-style: none;

        li {
            margin-top: 10px;
            position: relative;
            padding-left: 15px;

            &:before {
                content: '';
                width: 5px;
                height: 5px;
                background-color: #fff;
                position: absolute;
                left: 0px;
                top: 8px;
                border-radius: 50%;
            }

            &:first-child {
                margin-top: 0px;
            }
        }
    }
}

.tab-list-box {
    .css-ptiqhd-MuiSvgIcon-root {
        font-size: 1.5rem;
        position: relative;
        top: 6px;
        background: #ccc;
    }
}

.pt-68 {
    padding-top: 68px;
}

.agenda-left-sticky {
    position: sticky;
    top: 75px;
}

.faculty-sticky {
    position: sticky;
    top: 75px;
}

.faculty-sticky-left {
    position: sticky;
    top: 75px;
    max-height: 600px;
    overflow: auto;
}

.events-date-place {
    padding-top: 10px;
    p {
        span {
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-size: 14pt !important;
            line-height: 28px !important;
        }
    }
}
.popup-close-btn:hover, .popup-close-btn:focus {
    background: transparent !important;
}
.faculty-left-btns {
    padding: 10px !important;
    p {
        font-size: 12pt;
        line-height: 27px;
        // text-align: justify;
    }
}
.faculty-tab-right-content {
    p {
        font-size: 12pt;
        line-height: 27px;
        // text-align: justify;
    }
}
.organizations-para {
    h3 {
        margin-bottom: 10px;
        span {
            font-size: 18px !important;
            display: block;
        }
    }
    p {
        span {
            font-family: Helvetica;
            font-size: 17px !important;            
            line-height: 28px;        
        } 
    }
}
.venues-addresss {
    p {
        font-family: Helvetica;
        font-size: 12pt;
        color: #4d4d4d;
        line-height: 26px;
        &.addss-heads {
            font-size: 14pt;
        }
    }
}

.details-tab-contents {
    p {
        span {
            font-family: Helvetica;
            font-size: 17px !important;            
            line-height: 28px;        
        }        
    }
}


.faulculty-mobile-modals {
    p {
        font-family: Helvetica;
    }
    .faculty-tab-right-content {
        padding-bottom: 20px;
        padding-top: 0px;
    }
    hr {
        margin-bottom: 20px;
    }
    .faculty-left-btns {
        padding: 1rem !important;  
    }
    .faculty-profiles {
        width: 108px !important;
        flex-basis: 108px;
    }
    .faculty-profiles-ctns {
        flex-basis: calc(100% - 108px);
        padding-left: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {            
            line-height: 26px;
            margin-bottom: 5px;
        }
    }
}
.faculty-righ-dsk {
    display: none;
}
.faculty-left-dsk {
    padding-right: 0px !important;
    margin: auto !important;
    .faculty-tb-btns {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 0px;
    }
    .faculty-profiles {
        width: 90px;
        flex-basis: 90px;
    }
    .faculty-profiles-ctns {
        flex-basis: calc(100% - 90px);
        padding-left: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;    
        p {
            margin-top: 5px;
        }
    }
}

@media(max-width: 1299px) {
    .faculty-sticky-left {
        position: static;
        max-height: inherit
    }
    .events-outers-row {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    
}

@media(max-width: 991px) {
    .footer-common-box {
        padding: 50px 0;        
    }    
    .venue-datetimes {
        padding: 2rem !important;
    }
    .venuses-cols {
        margin: 0px !important;
    }
    .venues-addresss {
        img {
            margin-top: 25px;
        }
        iframe {
            margin-top: 20px;
            width: 100%;
        }
    }
    .faulculty-mobile-modals {
        .faculty-profiles-ctns {
            padding-right: 40px;
        }
    }  

    
}

@media(max-width: 767px) {
    #root {
        padding-top: 56px;
    }

    .footer-common-box {
        padding: 35px 0;

        p {
            line-height: 26px;

            span {
                line-height: 26px !important;
                font-size: 1rem !important;
            }
        }

        ul {
            li {
                span {
                    line-height: 26px !important;
                    font-size: 1rem !important;
                }
            }
        }
    }

    .banner-box {
        .column {
            span {
                font-size: 32px !important;
            }
        }

        .banner-content {
            p {
                span {
                    font-size: 1rem !important;
                    line-height: 26px !important;
                }
            }
        }
    }

    .banner-box>div {
        height: 500px !important;
    }

    .event-heading-top {
        display: block !important;

        .event-heading-right {
            justify-content: center;

            .imglogoes {
                margin: 10px 0 20px;
            }
        }
    }
    .venue-datetimes {
        padding: 20px !important;
    }
    .events-date-place {
        p {
            span {     
                font-size: 1rem !important;
                line-height: 26px !important;
            }
        }
    }
    .popup-text {
        h5 {
            span {
                font-size: 1.1rem !important;
                line-height: 28px !important;
            }
        }   
    }
    .venues-addresss {
        p {
            font-size: 1rem !important;
            line-height: 26px !important;
        }
    }
    .faulculty-mobile-modals {
        .faculty-profiles-ctns {
            padding-left: 15px;
        }
    }
    .faculty-left-dsk {
        .faculty-profiles-ctns {
            padding-left: 15px;
        }
    }
    
}

@media(max-width: 499px) {
    .faulculty-mobile-modals {
        .faculty-left-btns {
            display: block;
        }
        .faculty-profiles-ctns {
            padding-left: 0px;
            padding-right: 0px;
            
        }
        .faculty-profiles {
            margin-bottom: 10px;
        }
    }    
    .faculty-left-dsk {
        .faculty-profiles { 
            margin-bottom: 10px;
        }
        .faculty-left-btns {
            display: block;
        }
        .faculty-profiles-ctns {
            padding-left: 0px;
        }
    }
    
}