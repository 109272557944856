.about-box {
    padding: 60px 0;
    p {
        span {
            font-family: Helvetica;
            line-height: 28px;
        }
    }
    ul {        
        list-style-type: none;
        li {
            margin-top: 15px;
            position: relative;
            padding-left: 20px;
            &:before {
                content: '';
                width: 6px;
                height: 6px;
                background-color: #333;
                position: absolute;
                left: 3px;
                top: 6px;
                display: block;
                border-radius: 50%;
            }
            a {
                color: #ffae00 !important;
            }
        }
    }
}
@media(max-width: 1199px) {
    .about-box {
        padding: 50px 0;
    }
}

@media(max-width: 767px) {
    .about-box {
        padding: 35px 0;
        p {
            span {
                font-size: 1rem !important;
                line-height: 26px !important;
            }
        }
        ul {        
            list-style-type: none;
            li {
                margin-top: 10px;
                span {
                    font-size: 1rem !important;
                    line-height: 26px !important;
                }
                &:before {
                    left: 4px;
                    top: 10px;
                    display: block;
                    border-radius: 50%;
                }                
            }
        }
    }
}