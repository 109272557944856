.logout-modal-wrapper {
    width: 64%;
    background: #fff;
    border-radius: $rounded-primary;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px;

    .icon-container {
        background: $primary;
        border-radius: $rounded-primary;
        display: flex;
        justify-content: center;
        padding: 35px;
        width: 100%;
        font-size: 70px;
    }

    .logout-icon {
        color: #fff;
        transform: rotate(180deg);
    }

    .title {
        font-family: $font-manrope;
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        margin: 24px auto 32px;
    }

    .button-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 17px;
        font-family: $font-manrope;
    }
}

@media(max-width: 600px) {
    .logout-modal-wrapper {
        width: 90%;

        .icon-container {
            font-size: 48px;
        }

        .title {
            font-size: 20px;
        }
    }
}