.location-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    font-family: "Manrope";
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    margin-top: 16px;
    margin-bottom: 24px;

    span {
        &:nth-child(1) {
            padding-right: 16px;
            border-right: 1px solid $gray-300;
        }

    }
}

.welcome-container {
    // margin-bottom: 40px;

    p {
        font-size: 20px;
        line-height: 29px;
    }
}

@media(max-width: 480px) {
    .location-container {
        flex-direction: column;
        align-items: start;
        gap: 8px;

        span {
            &:nth-child(1) {
                border-right: none;
            }
    
        }
    }
}