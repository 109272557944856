.contactus-box {
    padding: 60px 0;
    h3 {
        position: relative;
        min-height: 52px;
        display: flex;
        align-items: center;
        padding-left: 21px;
        &:before {
            content: '';
            background-color: rgb(255, 174, 0);
            width: 5px;
            height: 52px;
            display: block;
            position: absolute;
            left: 0px;
            top: 0px;
        }
        span {
            font-family: "DM Serif Display" ;
            font-size: 1.55rem !important;
            font-weight: 700;
            letter-spacing: 1.56px;
        }        
    }
    .contactleft-img>div {
        height: auto;
        max-width: 400px;
        float: right;
    }        
}


@media(max-width: 991px) {
    .contactus-box {
        padding: 50px 0;
        .css-11lq3yg-MuiGrid-root {
            display: block;
        }
        .css-z4l9er-MuiGrid-root {
            padding: 0 0 25px 0;
            max-width: 100%;
            width: 100%;            
        }
        .css-1c1b2h0-MuiGrid-root {
            max-width: 100%;
            width: 100%;   
        }        
        .contactleft-img>div {
            max-width: 400px;
            margin: auto;            
            float: none;

        } 
        
    }
}

@media(max-width: 767px) {
    .contactus-box {
        padding: 35px 0;
        p {
            line-height: 26px !important;
            span {
                font-size: 1rem !important;
                line-height: 26px !important;
            }
        }
    }
}