$primary: rgba(1, 102, 152, 1);
$blue-overlay: rgba(0, 65, 141, 0.749);
$border-primary: rgba(217, 217, 217, 1);
$border-input: rgba(128, 128, 128, 1);
$gray-300: rgba(100, 100, 100, 1);
$dark: rgba(51, 51, 51, 1);
$yellow: rgba(255, 174, 0, 1);
$stroke: rgba(248, 248, 248, 1);
$red: rgba(227, 4, 4, 1);

// fonts
$font-redHat: "Red Hat Display";
$font-dmSerif: "DM Serif Display";
$font-poppins: Poppins;
$font-calibri: Calibri;
$font-swissBT: "Swiss 721 BT";
$font-swissLT: "Swiss 721 LT";
$font-avenir: "Avenir";
$font-mulish: "Mulish";
$font-manrope: "Manrope";
$font-inter: "Inter";

// border-radius
$rounded-primary: 16px;