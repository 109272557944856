.topbar-container {
    height: 72px;
    padding: 25px 0;
    background-color: #fff;
    width: 100%;
    padding-left: 24px;
}

.custom-logo-wrapper {
    margin-left: auto;
    max-width: 564px;

    &>div {
        width: fit-content;
    }
}

// main content
.auth-layout-main-container {
    background-color: #fcfcfc;
    min-height: calc(100% - 72px);
    padding: 32px 24px 95px;
}

@media (max-width: 850px) {
    .topbar-container {
        position: fixed;
        top: 0;
        border-bottom: 1px solid $border-primary;
    }

    .custom-logo-wrapper {
        margin: 0 auto;
        max-width: 100%;

        &>div {
            width: fit-content;
        }
    }

    .auth-layout-main-container {
        padding: calc(72px + 32px) 32px 32px;
    }

    .auth-layout-bg-image-container {
        display: none;
    }

}

@media (max-width: 768px) {
    .auth-layout-main-container {
        padding: 40px 20px 60px;
    }
}