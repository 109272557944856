.header-box {
    transition: all 0.3s;
    position: fixed !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);

    .logo-img {
        .mui-image-wrapper {
            transition: all 0.3s;
        }
    }

    .header-inner {}

    .nav-link-gap {
        gap: 44px;
    }

    .nav-links {
        justify-content: center;
    }

    .cta-menu {
        display: flex;
        align-items: center;
        gap: 16px;
    }
}

.menu-hover-container {
    position: relative;
    font-family: $font-manrope;
    font-size: 16px;
    line-height: 23px;
    color: $dark;

    .menu-contents {
        position: absolute;
        top: 100%;
        right: 0;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        min-width: 150px;
    }

    &:hover {
        .menu-contents {
            opacity: 1;
            visibility: visible;
        }
    }

    ul {
        list-style: none;
        background: #fff;
        padding: 12px 12px;
        border: 1px solid $border-primary;
        border-radius: 8px;
        width: 100%;
        margin-top: 20px;
        position: relative;

        &::after {
            content: '';
            width: 20px;
            height: 20px;
            background: #fff;
            border-top: 1px solid $border-primary;
            border-left: 1px solid $border-primary;
            position: absolute;
            top: -11px;
            right: 24px;
            transform: rotate(45deg);
        }

        button {
            border: none;
            background: transparent;
            font-family: $font-manrope;
            color: inherit;
        }

        a {
            color: inherit;
        }

        .item {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 8px 0;
            border-bottom: 1px solid $border-primary;
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
            transition: all 0.15s;

            &:hover {
                color: $primary;
            }

            &:last-child {
                border-bottom: none;
            }

            .icon {
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background: $primary;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 10px;
            }
        }
    }
}

@media(max-width: 1200px) {
    .header-box {
        .nav-link-gap {
            gap: 10px;
        }
    }
}

@media(max-width: 1024px) {
    .header-box {
        .nav-links {
            justify-content: end;
        }

        .cta-menu {
            .get-in-touch {
                display: none;
            }

            .user-phone {
                display: none;
            }

            .MuiButton-startIcon {
                margin-left: 0;
                margin-right: 0;
            }

            .user-menu-icon {
                min-width: 0;
                padding: 5px !important;
                border-radius: 50% !important;
                min-width: 100px;
                min-height: 40px;
                padding: 5px !important;
                border-radius: 68px !important;
            }
        }
    }
}

@media(max-width: 850px) {
    .header-box {
        .header-inner {
            padding: 16px 7px;
        }
    }
}

@media(max-width: 600px) {
    .header-box {
        .header-inner {
            padding: 16px 7px;
        }
    }
}