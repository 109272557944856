@for $i from 1 through 100 {
    .fz-#{$i} {
        font-size: #{$i}px !important;
    }
}

$weights: 100, 200, 300, 400, 500, 600, 700, 800, 900;

// Loop through the weights to generate classes
@each $weight in $weights {
    .fw-#{$weight} {
        font-weight: $weight !important;
    }
}