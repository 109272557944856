.custom-slider-container {

    .slick-slide {
        padding-right: 24px;
        height: 100%;

        &:last-child {
            padding-right: 0;
        }
    }

    .custom-arrow {
        font-family: $font-redHat;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: $primary;
        background: transparent;
        border: none;
    }

    .slick-prev,
    .slick-next {
        right: 0 !important;
        top: 0 !important;
        transform: translate(0, 0) !important;
        width: fit-content !important;
        height: fit-content !important;
        z-index: 2;
        margin-top: 45px;

        &:hover,
        &:focus {
            color: $primary !important;
        }

        &::before {
            display: none;
        }

        &.slick-disabled {
            opacity: 0.5;
        }
    }

    .slick-prev {
        position: absolute;
        bottom: 0 !important;
        width: fit-content !important;
        height: fit-content !important;
        top: unset !important;
        left: 0;
    }

    .slick-next {
        margin-left: auto;
    }

    // pagination

    .MuiPagination-root {
        margin-top: -32px;
    }

    .MuiPagination-ul {
        align-items: center;
        justify-content: center;

        li {

            &:last-child,
            &:first-child {
                display: none;
            }

            .MuiPaginationItem-root {
                display: block;
                background: transparent;
                color: #016698;
                border-radius: 8px;
                height: 40px !important;
                width: 40px !important;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &.Mui-selected {
                    background: #EEF9FD;
                }
            }
        }
    }
}

@media(max-width: 600px) {

    .custom-slider-container {

        .slick-slide {
            padding-right: 5px;
        }

        .MuiPagination-root {
            display: none;
        }

        .slick-prev,
        .slick-next {
            margin-top: 24px;
        }

    }
}