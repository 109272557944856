.event-box {
    padding: 70px 0;

    .css-11lq3yg-MuiGrid-root {
        margin-left: -8px;
        margin-right: -8px;
        width: auto;
        justify-content: center;
    }

    .css-1d3bbye {
        justify-content: center;
    }

    .css-1a85c2i-MuiTypography-root {
        letter-spacing: 1.56px;
    }

    // .mui-image-wrapper {
    //     overflow: hidden;
    //     .mui-image-img {            
    //         transition: 5s all;
    //         &:hover {
    //             -webkit-transform: scale(1.08);
    //             transform: scale(1.08);   
    //         }
    //     }

    // }
}


.event-tab-box {
    padding: 70px 0;
}

.gallery-box {
    padding: 60px 0;

    .css-1a85c2i-MuiTypography-root {
        letter-spacing: 1.56px;
        font-family: "DM Serif Display";
    }

    .image-cards {
        overflow: hidden;

        img {
            transition: 0.1s all;
        }

        &:hover {
            img {
                transform: scale(1.15);
            }
        }

    }
}

// auth
.event-details-container-auth {
    margin-top: 52px;
    margin-bottom: 64px;

    .date-location {
        margin-top: 16px;
        margin-bottom: 40px;
        font-family: $font-redHat;
        font-size: 20px;
        font-weight: 600;
        line-height: 22px;
        display: flex;
        align-items: center;
        gap: 80px;
        flex-wrap: wrap;

        .icon {
            color: $primary;
        }
    }

    p {
        font-family: $font-manrope;
        font-size: 20px;
        font-weight: 400;
        line-height: 29px;
        text-align: left;
        color: $gray-300;
    }
}

@media(max-width: 991px) {
    .gallery-box {
        padding: 40px 0;

        .css-3syr29-MuiGrid-root {
            margin-top: 0px;
        }
    }

    .event-box {
        padding: 40px 0;
    }

    .event-tab-box {
        padding: 70px 0 40px;
    }
}

@media(max-width: 767px) {
    .event-box {
        padding: 35px 0;
    }

    .gallery-box {
        padding: 35px 0;
    }

    .event-tab-box {
        padding: 70px 0 35px;
    }

    .event-details-container-auth {
        .date-location {
            flex-direction: column;
            align-items: start;
            gap: 12px;
        }
    }
}


@media(max-width: 480px) {}