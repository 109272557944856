.blue-content-box {
    padding: 50px 0;
    text-align: center;
}

@media(max-width: 767px) {
    .blue-content-box {
        padding: 35px 0;    
        p {
            font-size: 1rem;
            span {
                font-size: 1rem !important;
                line-height: 26px !important;
            }
        } 
    }   
}