.custom-pagination {
    padding-bottom: 16px;
    padding-top: 12px;

    .MuiPagination-ul {
        align-items: center;
        justify-content: center;

        li {

            &:last-child,
            &:first-child {
                .MuiPaginationItem-root {
                    height: auto !important;
                    width: 89px !important;
                }
            }

            &:first-child {
                margin-right: auto;
            }

            &:last-child {
                margin-left: auto;

                .MuiBox-root {
                    justify-content: end;
                }
            }

            .MuiPaginationItem-root {
                display: block;
                background: transparent;
                color: #016698;
                border-radius: 8px;
                height: 40px !important;
                width: 40px !important;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &.Mui-selected {
                    background: #EEF9FD;
                }
            }
        }
    }
}

@media(max-width: 480px) {
    .custom-pagination {

        // hide pagination counts only
        .MuiPagination-ul {
            li {
                display: none;

                &:last-child,
                &:first-child {
                    display: block;
                }
            }
        }
    }

}