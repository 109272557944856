.footer-box {
    border-top: 4px solid $yellow;

    .footer-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 40px 0;
    }

    .footer-links {
        margin-top: 15px;
        justify-content: center;
        align-items: center;

        >div {
            padding-left: 30px;
            position: relative;
            margin-top: 16px;

            &:before {
                content: '';
                width: 1px;
                height: 16px;
                background-color: #fff;
                position: absolute;
                left: 15px;
                top: 6px;
            }

            &:first-child {
                padding-left: 0px;

                &:before {
                    display: none;
                }
            }
        }

        .MuiGrid-root.MuiGrid-item {
            border-right: 1px solid $stroke;
            padding: 0 32px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
                border-right: none;
            }
        }

        .MuiTypography-root {
            font-family: $font-redHat !important;
        }

        .css-1b43hcw-MuiTypography-root:hover {
            color: rgba(255, 255, 255, 0.8);
        }
    }
}

.copyright-box {
    padding: 16px 0 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .MuiTypography-root {
        font-size: 14px;
        line-height: 20px;
        font-family: $font-manrope;
    }

    p {
        font-size: 1rem;
        color: #fff;
        text-align: center;
    }
}

.social-container {
    display: flex;
    align-items: center;
    gap: 20px;

    a {
        color: #fff;

        &:hover {
            color: rgba(255, 255, 255, 0.8);
        }
    }
}

@media(max-width: 991px) {
    .footer-box {
        .footer-links {
            margin-top: 10px;
        }
    }
}

@media(max-width: 767px) {
    .footer-box {
        .footer-inner {
            padding: 25px 0;
        }

        .css-1vtqtpw-MuiGrid-root {
            width: calc(100% + 10px);
            margin-left: -10px;
        }

        .css-1vtqtpw-MuiGrid-root>.MuiGrid-item {
            padding-top: 10px;
            padding-left: 10px;
        }
    }

    .copyright-box {
        padding: 20px 0;
    }
}

@media(max-width: 480px) {
    .copyright-box {
        flex-direction: column;
        gap: 16px;
    }
}