.card-v2 {
    width: fit-content;
    border-radius: $rounded-primary;
    border: 1px solid rgba(217, 217, 217, 1);
    color: #fff;
    background: #fff;
    overflow: hidden;
    // min-width: 350px;
    width: 100%;
    height: 100%;
    position: relative;

    .img-container {
        min-height: 150px;
        background: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    img {
        object-fit: cover;
    }

    .card-body {
        background: #fff;
        color: $dark;
        padding: 24px;
        padding-bottom: 50px;
        border-radius: $rounded-primary;
        border-top: 8px solid #FFAE00;
        transform: translateY(-14px);
        height: 100%;
        min-height: 130px;

        .date {
            font-family: $font-manrope;
            font-size: 12px;
            font-weight: 500;
            line-height: 17.4px;
            text-align: left;
            text-transform: uppercase;
            margin-bottom: 12px;
            color: $dark;
        }

        .heading {
            font-family: $font-redHat;
            font-size: 18px;
            font-weight: 600;
            line-height: 26px;
            text-align: left;
            color: $dark;
            margin-bottom: 8px;
        }

        .description {
            font-family: $font-manrope;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: $border-input;
            margin-bottom: 24px;
        }
    }
}

.flex-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;

    .card-v2 {

        .img-container {
            max-width: 340px;
        }

        .card-body {
            max-height: 300px;
        }
    }
}

.custom-card-multi-border {
    border-top: 24px solid $primary;
    border-radius: $rounded-primary;
    color: $dark;
    font-family: $font-manrope;
    margin-bottom: 35px;

    .top {
        background: white;
        padding: 26px 26px 36px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        border-top: 4px solid $yellow;
        margin-top: -14px;
    }

    .title {
        font-size: 20px;
        font-weight: 700;
        line-height: 29px;
        color: $primary;
    }

    .subtitle {
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        text-align: left;
        margin: 8px 0;
        max-width: 700px;
    }

    .prof {
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
    }

    .bottom {
        background: rgba(241, 243, 244, 1);
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        padding: 12px 32px;
    }

    .card-v2 {
        .card-body {
            padding-bottom: 2px;
        }
    }

    .rounded-btn.MuiButtonBase-root {
        background: $primary;
        color: #fff;
        width: 50px !important;
        height: 50px !important;
        min-width: 50px !important;
        min-height: 50px !important;
        border-radius: 50% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-bottom: 16px;
        margin-top: 24px;
    }

    .rounded-btn.download.MuiButtonBase-root {
        position: absolute;
        bottom: 0;
        right: 38px;
    }
}

@media(max-width: 480px) {
    .card-v2 {
        min-width: auto;
    }

    .custom-card-multi-border {
        .rounded-btn.MuiButtonBase-root {
            margin-right: 0;
        }

        .bottom {
            padding: 12px;
        }
    }
}