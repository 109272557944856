.auth-card-custom {
    border-radius: 32px;
    border: 1px solid $border-primary;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 540px;
    background: #fff;
    margin-left: auto;

    &>div {
        max-width: 352px;
    }
}

.auth-heading40 {
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    font-family: "Red Hat Display";
    text-align: center;
}

.auth-subtitle {
    color: rgba(100, 100, 100, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    font-family: "Manrope";
    text-align: center;
    margin-top: 24px;
    margin-bottom: 32px;
}

@media (max-width: 850px) {
    .auth-card-custom {
        margin: 0 auto;
        // margin-top: 60px;
    }
}

@media (min-width: 480px) and (max-width: 768px) {
    .auth-card-custom {
        margin-top: 60px;
    }
}